import currencyFormatService from '@/services/formatters/currencyFormatService.js';
export default {
  props: ['visible', 'timePeriod', 'articleCharacteristicsTable', 'loading'],
  data: () => ({
    articleArticleCharacteristicsHeader: [{
      text: 'Artikel Name',
      value: 'articleDescription'
    },
    // { text: 'Merkmal 1', value: 'sm1' },
    // { text: 'Merkmal 2', value: 'sm2' },
    // { text: 'Merkmal 3', value: 'sm3' },
    {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    search: ''
  }),
  methods: {
    itemRowBackground: function (item) {
      if (item.articleType == this.articleTypeTemp || item.category == this.articleCategoryTemp || item.articleNumber == this.articleArticleTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    formatEuro(value) {
      return currencyFormatService.formatEuro(value);
    }
  }
};