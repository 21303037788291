import { mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
export default {
  data: () => ({
    address: null
  }),
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response);
        this.address = response.data;
      });
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    lfdnr() {
      this.init();
    }
  }
};