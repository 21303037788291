import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "informationTabAlign"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Krankenkasse",
      "prepend-inner-icon": "mdi-hospital-building",
      "readonly": true,
      "clearable": ""
    },
    model: {
      value: _vm.address.patient.healthInsuranceCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "healthInsuranceCompanyName", $$v);
      },
      expression: "address.patient.healthInsuranceCompanyName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Diabetes',
      "readonly": true
    },
    model: {
      value: _vm.address.patient.diseases.diabetes,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "diabetes", $$v);
      },
      expression: "address.patient.diseases.diabetes"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Angiopathie',
      "readonly": true
    },
    model: {
      value: _vm.address.patient.diseases.angiopathie,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "angiopathie", $$v);
      },
      expression: "address.patient.diseases.angiopathie"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Neuropathie',
      "readonly": true
    },
    model: {
      value: _vm.address.patient.diseases.neuropathie,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "neuropathie", $$v);
      },
      expression: "address.patient.diseases.neuropathie"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VTextarea, {
    attrs: {
      "clearable": "",
      "rows": "2",
      "label": "Allergien",
      "outlined": "",
      "prepend-inner-icon": "mdi-clipboard-plus-outline",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.diseases.allergies,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "allergies", $$v);
      },
      expression: "address.patient.diseases.allergies"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Versichertengruppe",
      "prepend-inner-icon": "mdi-tag-text",
      "clearable": "",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.insuredGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "insuredGroupName", $$v);
      },
      expression: "address.patient.insuredGroupName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Gebührenfrei',
      "readonly": true
    },
    model: {
      value: _vm.address.patient.feeReady,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "feeReady", $$v);
      },
      expression: "address.patient.feeReady"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-calendar-blank",
      "label": "Regojahr",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.regojahr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "regojahr", $$v);
      },
      expression: "address.patient.regojahr"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* SVNR",
      "append-icon": "mdi-card-account-details",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.svnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "svnr", $$v);
      },
      expression: "address.patient.svnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* SVNR Dat.",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.svnrDat,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "svnrDat", $$v);
      },
      expression: "address.patient.svnrDat"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-doctor",
      "label": "* Arzt",
      "clearable": "",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.familyDoctorName,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "familyDoctorName", $$v);
      },
      expression: "address.patient.familyDoctorName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "HV-Nr.",
      "readonly": true
    },
    model: {
      value: _vm.address.patient.familyDoctorHvnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "familyDoctorHvnr", $$v);
      },
      expression: "address.patient.familyDoctorHvnr"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };