import addressService from '@/services/crm/addressService.js';
export default {
  props: {
    address: {
      required: true
    }
  },
  data() {
    return {
      countries: null,
      streets: null,
      streetSearchInput: null,
      modifiedData: {
        streetName: null,
        countryName: null,
        country: null,
        zip: null,
        city: null,
        street: null,
        tel: null,
        tel2: null,
        mobile: null,
        email: null
      },
      saving: false,
      opened: true
    };
  },
  methods: {
    cancel() {
      this.$emit('closeDialog');
    },
    save() {
      const viewModel = this;
      const data = this.modifiedData;
      data.streetName = this.streetSearchInput;
      this.saving = true;
      addressService.updateContactData(this.address.key.lfdnr, {
        address: {
          plz: data.zip,
          street: data.streetName,
          city: data.city,
          country: data.country
        },
        contactData: {
          tel1: data.tel,
          tel2: data.tel2,
          mobil: data.mobile,
          email: data.email
        }
      }).then(() => {
        this.saving = false;
        this.modifiedData.countryName = addressService.getCountryNameForId(this.countries, data.country);
        viewModel.$emit('saved', viewModel.modifiedData);
        viewModel.$emit('closeDialog');
      }).catch(err => {
        this.saving = false;
        viewModel.$emit('error', err);
      });
    },
    getCountries() {
      addressService.getCountries().then(countries => {
        this.countries = countries;
      }).catch(err => {
        this.$emit('error', err);
      });
    },
    updateCity() {
      const zip = this.modifiedData.zip;
      const country = this.modifiedData.country;
      addressService.getCityForZip(zip, country).then(city => {
        this.modifiedData.city = city;
      });
    },
    searchForStreet() {
      const query = this.streetSearchInput;
      if (query != null && String(query.length > 2)) {
        const zip = this.modifiedData.zip;
        addressService.getStreetNames(zip, query).then(streetNames => {
          this.streets = streetNames.streets;
        });
      }
    }
  },
  mounted() {
    if (this.address != null) {
      const data = this.modifiedData;
      data.country = this.address.address.country;
      data.zip = this.address.address.zip;
      data.city = this.address.address.city;
      data.street = this.address.address.street;
      data.tel = this.address.tel;
      data.tel2 = this.address.tel2;
      data.mobile = this.address.mobile;
      data.email = this.address.email;
    }
    this.getCountries();
  },
  watch: {
    streetSearchInput(query) {
      if (query === '') {
        this.streets = [];
      } else {
        this.searchForStreet();
      }
    }
  }
};