import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "informationTabAlign"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Krankenkasse",
      "prepend-inner-icon": "mdi-hospital-building",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.healthInsuranceCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "healthInsuranceCompanyName", $$v);
      },
      expression: "address.employee.healthInsuranceCompanyName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "SVNR",
      "append-icon": "mdi-card-account-details",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.svnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "svnr", $$v);
      },
      expression: "address.employee.svnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "SVNR Dat.",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.svnrDat,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "svnrDat", $$v);
      },
      expression: "address.employee.svnrDat"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Kassenmitarbeiter",
      "prepend-icon": "mdi-cash-register",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.cashier,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "cashier", $$v);
      },
      expression: "address.employee.cashier"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "BDE-Mitarbeiter",
      "prepend-icon": "mdi-database-edit",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.bdeEmployee,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "bdeEmployee", $$v);
      },
      expression: "address.employee.bdeEmployee"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Externer Mitarbeiter",
      "prepend-icon": "mdi-account-arrow-left",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.externalEmployee,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "externalEmployee", $$v);
      },
      expression: "address.employee.externalEmployee"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Personalnummer",
      "append-icon": "mdi-numeric",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.staffId,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "staffId", $$v);
      },
      expression: "address.employee.staffId"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Ausweisnummer",
      "append-icon": "mdi-id-card",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.idNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "idNumber", $$v);
      },
      expression: "address.employee.idNumber"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VMenu, {
    ref: "menu_enter",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date_enter,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date_enter = $event;
      },
      "update:return-value": function ($event) {
        _vm.date_enter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "label": "Eintrittsdatum",
            "append-icon": "mdi-calendar-import",
            "readonly": true
          },
          model: {
            value: _vm.address.employee.entryDate,
            callback: function ($$v) {
              _vm.$set(_vm.address.employee, "entryDate", $$v);
            },
            expression: "address.employee.entryDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }, [_c(VDatePicker, {
    attrs: {
      "type": "date",
      "color": "primary",
      "no-title": "",
      "scrollable": ""
    }
  }, [_c(VSpacer)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VMenu, {
    ref: "menu_exit",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date_exit,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date_exit = $event;
      },
      "update:return-value": function ($event) {
        _vm.date_exit = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "label": "Austrittsdatum",
            "append-icon": "mdi-calendar-export",
            "readonly": true
          },
          model: {
            value: _vm.address.employee.exitDate,
            callback: function ($$v) {
              _vm.$set(_vm.address.employee, "exitDate", $$v);
            },
            expression: "address.employee.exitDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }, [_c(VDatePicker, {
    attrs: {
      "type": "date",
      "color": "primary",
      "no-title": "",
      "scrollable": ""
    }
  }, [_c(VSpacer)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-account",
      "label": "Loginname",
      "outlined": "",
      "clearable": "",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.userId,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "userId", $$v);
      },
      expression: "address.employee.userId"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "clearable": "",
      "prepend-inner-icon": "mdi-warehouse",
      "label": "Lager",
      "readonly": true
    },
    model: {
      value: _vm.address.employee.warehouseName,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "warehouseName", $$v);
      },
      expression: "address.employee.warehouseName"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };