import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "my-5 grey lighten-2 elevation-0 rounded-pill mx-2 pa-1",
    staticStyle: {
      "font-size": "20px",
      "hyphens": "auto",
      "width": "fit-content"
    }
  }, [_c(VAvatar, {
    staticClass: "elevation-0",
    attrs: {
      "size": "34",
      "color": "white"
    }
  }, [_c(VIcon, {
    staticClass: "pa-4"
  }, [_vm._v(_vm._s(_vm.icon))])], 1), _c('span', {
    staticClass: "ml-2 mr-3"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };