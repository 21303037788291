import "core-js/modules/es.array.at.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.at-alternative.js";
import { mapState } from 'vuex';
import Revenue from '@/services/statistics/revenues.js';
import addressService from '@/services/crm/addressService.js';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
import RevenuePerArticleProperty from '@/components/statistics/general/RevenuePerArticleProperty.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter,
    RevenuePerArticleProperty
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    articleTypeTemp: '',
    articleCategoryTemp: '',
    articleArticleTemp: '',
    articleTypes: [],
    articleTypesRevenue: [],
    articleCategoryCard: false,
    articleTypeCard: false,
    articleArticleCard: false,
    articleCharacteristicsCard: false,
    timePeriod: '',
    revenue: [],
    loading: true,
    loading2: true,
    loading3: true,
    loading4: true,
    fromdate: '',
    todate: '',
    currentDate: '',
    search: '',
    search1: '',
    search2: '',
    search3: '',
    search4: '',
    openedWithChart: false,
    openedWithChartCounter: false,
    revenueIncreaseData: [],
    revenueData: [],
    sortDesc: false,
    editorContent: '',
    articleListingHeader: [{
      text: 'Artikelname',
      value: 'description'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    articleTypeHeader: [{
      text: 'Artikel Typ',
      value: 'description'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    articleCategoryHeader: [{
      text: 'Artikel Kategorie',
      value: 'description'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    articleArticleHeader: [{
      text: 'Artikel Name',
      value: 'description'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    articleArticleCharacteristicsHeader: [{
      text: 'Artikel Name',
      value: 'art_desc'
    }, {
      text: 'Merkmal 1',
      value: 'sm1'
    }, {
      text: 'Merkmal 2',
      value: 'sm2'
    }, {
      text: 'Merkmal 3',
      value: 'sm3'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }],
    headers: [{
      text: 'bis Anfangsdatum',
      value: 'revenueFromBeginning',
      align: 'end'
    }, {
      text: 'definierter Zeitraum',
      value: 'revenue',
      align: 'end'
    }, {
      text: 'seit Enddatum',
      value: 'revenueUntil Now',
      align: 'end'
    }, {
      text: 'Gesamtumsatz',
      value: 'total Sales',
      align: 'end'
    }],
    // Chart
    series: [{
      name: 'Euro (€)',
      data: []
    }],
    chartOpts: {
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            orientation: 'vertical',
            position: 'top' // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: false,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#101010']
        },
        formatter: value => currencyFormatService.formatEuro(value)
      },
      xaxis: {
        categories: [''],
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: value => currencyFormatService.formatEuro(value)
        }
      },
      colors: ['#FF6633', '#ED0713', '#338B36', '#34dbeb'],
      set setXaxis(value) {
        this.xaxis = value;
      },
      set setTitle(value) {
        this.title = value;
      }
    },
    lineChartOptions: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      legend: {
        showForSingleSeries: true
      },
      dataLabels: {
        enabled: true,
        formatter: value => currencyFormatService.formatEuro(value)
      },
      markers: {
        size: 0
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        }
      },
      yaxis: {
        labels: {
          formatter: value => currencyFormatService.formatEuro(value)
        },
        title: {
          text: 'Umsatz'
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'dd.MM.yyyy',
            day: 'dd.MM.yyyy',
            hour: 'HH:mm'
          }
        }
      },
      tooltip: {
        shared: true,
        x: {
          format: 'dd.MM.yyyy'
        },
        y: {
          formatter: value => {
            return currencyFormatService.formatEuro(value);
          }
        }
      }
    },
    showLineChart: -1,
    lineChartData: [],
    articleType: -1,
    articleCategory: -1,
    articleTypesTable: [],
    articleCategoriesTable: [],
    articleArticlesTable: [],
    typeCategoryArticleTab: null,
    articleCharacteristicsTable: [],
    chartArticleSearch: '',
    chartArticleTable: [],
    selectedLineChartIndex: -1,
    adressTypeName: [],
    clickedCounter: 0
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleTypeStatistics();
    },
    itemRowBackground2: function (item) {
      if (item.articleType == this.articleTypeTemp || item.category == this.articleCategoryTemp || item.articleNumber == this.articleArticleTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    loadArticleTypeStatistics() {
      this.timePeriod = this.filterFromDate + ' bis ' + this.filterToDate;
      this.loading = true;
      var chartData = [];
      var chartRevenueData = [];
      var chartPersData = [];
      var chartKKData = [];
      var chartPrescData = [];
      Revenue.getRevenueList(this.lfdnr, '1000-01', this.filterFromDate).then(response => {
        var obj = response.data;
        chartRevenueData.push(obj.revenue);
        chartPersData.push(obj.priv_revenue);
        chartKKData.push(obj.kk_revenue);
        chartPrescData.push(obj.prescriptions);
        Revenue.getRevenueList(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
          var obj = response.data;
          chartRevenueData.push(obj.revenue);
          chartPersData.push(obj.priv_revenue);
          chartKKData.push(obj.kk_revenue);
          chartPrescData.push(obj.prescriptions);
          var heute = new Date();
          var monat = heute.getMonth() + 1;
          monat = monat.toString();
          if (monat.length < 2) {
            monat = '0' + monat;
          }
          Revenue.getRevenueList(this.lfdnr, this.filterToDate, heute.getFullYear().toString() + '-' + monat.toString()).then(response => {
            var obj = response.data;
            chartRevenueData.push(obj.revenue);
            chartPersData.push(obj.priv_revenue);
            chartKKData.push(obj.kk_revenue);
            chartPrescData.push(obj.prescriptions);
            Revenue.getRevenueList(this.lfdnr, '1000-01', heute.getFullYear().toString() + '-' + monat.toString()).then(response => {
              var obj = response.data;
              chartRevenueData.push(obj.revenue);
              chartPersData.push(obj.priv_revenue);
              chartKKData.push(obj.kk_revenue);
              chartPrescData.push(obj.prescriptions);
              chartData.push({
                name: 'Gesamtumsatz',
                data: chartRevenueData
              });
              chartData.push({
                name: 'Privat-Umsatz',
                data: chartPersData
              });
              chartData.push({
                name: 'KK-Umsatz',
                data: chartKKData
              });
              chartData.push({
                name: 'Verschreibungen',
                data: chartPrescData
              });
              this.updateChart(chartData);
            });
          });
        });
      });
    },
    openArticleType(item) {
      if (this.articleTypeTemp == item.articleType) {
        this.articleTypeTemp = '';
        this.articleCategoryCard = false;
        this.articleArticleCard = false;
        this.articleCharacteristicsCard = false;
        this.search = '';
      } else {
        this.articleTypeTemp = item.articleType;
        this.loading2 = true;
        this.articleCategoriesTable = [];
        this.typeCategoryArticleTab = 1;
        Revenue.getArticleCategoriesList(this.lfdnr, item.articleType, this.fromdate, this.todate).then(response => {
          this.articleCategoryCard = true;
          this.articleArticleCard = false;
          this.articleCharacteristicsCard = false;
          this.articleCategoriesTable = response.data;
          this.loading2 = false;
        });
      }
    },
    openArticleKategory(item) {
      if (this.articleCategoryTemp == item.category) {
        this.articleCategoryTemp = '';
        this.articleArticleCard = false;
        this.articleCharacteristicsCard = false;
        this.search2 = '';
      } else {
        this.articleCategoryTemp = item.category;
        this.loading3 = true;
        this.articleArticlesTable = [];
        this.typeCategoryArticleTab = 2;
        Revenue.getArticlesList(this.lfdnr, item.category, this.fromdate, this.todate).then(response => {
          this.articleArticleCard = true;
          this.articleCharacteristicsCard = false;
          this.articleArticlesTable = response.data;
          this.loading3 = false;
        });
      }
    },
    openArticleArticle(item) {
      if (this.articleArticleTemp == item.articleNumber) {
        this.articleArticleTemp = '';
        this.articleCharacteristicsCard = false;
        this.search3 = '';
      } else {
        this.articleArticleTemp = item.articleNumber;
        this.loading4 = true;
        this.typeCategoryArticleTab = 3;
        Revenue.getArticleDetailsList(this.lfdnr, item.articleNumber, this.fromdate, this.todate).then(response => {
          this.articleCharacteristicsCard = true;
          this.articleCharacteristicsTable = response.data;
          this.loading4 = false;
        });
      }
    },
    updateChart(chartData) {
      const chartHeaders = [];
      this.headers.forEach(h => chartHeaders.push(h.text));
      this.$refs.summaryChart.updateOptions({
        xaxis: {
          categories: chartHeaders
        }
      });
      this.$refs.summaryChart.updateSeries(chartData);
    },
    openLineChart(index) {
      this.articleCategoryCard = false;
      this.articleTypeCard = false;
      this.articleArticleCard = false;
      this.articleCharacteristicsCard = false;
      this.articleTypeTemp = '';
      this.articleCategoryTemp = '';
      this.articleArticleTemp = '';
      if (this.clickedCounter == 0) {
        this.clickedCounter++;
      } else {
        this.clickedCounter = 0;
        this.selectedLineChartIndex = -1;
      }
      if (this.showLineChart == index) {
        this.showLineChart = -1;
      } else {
        this.showLineChart = index;
      }
      if (this.showLineChart != -1) {
        this.fromdate = '1000-01';
        this.todate = this.filterFromDate;
        var currentDate = new Date();
        if (index == 1) {
          this.fromdate = this.filterFromDate;
          this.todate = this.filterToDate;
        } else if (index == 2) {
          this.fromdate = this.filterToDate;
          this.todate = currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2);
        } else if (index == 3) {
          this.fromdate = '1000-01';
          this.todate = currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2);
        }
        Revenue.getYearlyRevenueList(this.lfdnr, this.fromdate, this.todate).then(response => {
          this.lineChartData = response.data;
          var arr = response.data;
          this.revenueData.length = 0; //clear arr
          arr.forEach(item => {
            this.revenueData.push({
              x: new Date(item.revenueModel.year + '-' + item.revenueModel.month).getTime(),
              y: item.revenueModel.revenue
            });
          });
          let i = 0;
          this.revenueIncreaseData.length = 0; //clear arr
          arr.forEach(item => {
            if (i == 0) {
              this.revenueIncreaseData.push({
                x: new Date(item.revenueModel.year + '-' + item.revenueModel.month).getTime(),
                y: item.revenueModel.revenue
              });
            } else {
              this.revenueIncreaseData.push({
                x: new Date(item.revenueModel.year + '-' + item.revenueModel.month).getTime(),
                y: item.revenueModel.revenue + this.revenueIncreaseData[i - 1].y
              });
            }
            i++;
          });
          this.$refs.lineChart.updateSeries([
          // {
          //   name: 'Einzelumsätze Umsatzsteigerung',
          //   data: this.revenueData
          // },
          {
            name: 'Gesamtumsätze',
            data: this.revenueIncreaseData
          }], false, true);
        });
      }
    },
    openRevenueDataTable(index) {
      //if (this.openedWithChart == false) {
      this.openedWithChart = true;
      /*} else {
        this.openedWithChart = false
        this.selectedLineChartIndex = -1
      }*/

      this.currentDate = new Date();
      if (index == 0) {
        this.fromdate = '1000-01';
        this.todate = this.filterFromDate;
        this.currentDate = new Date();
        this.timePeriod = 'Beginn bis ' + this.todate;
      } else if (index == 1) {
        this.fromdate = this.filterFromDate;
        this.todate = this.filterToDate;
        this.timePeriod = this.fromdate + ' bis ' + this.todate;
      } else if (index == 2) {
        this.fromdate = this.filterToDate;
        this.todate = this.currentDate.getFullYear() + '-' + ('0' + (this.currentDate.getMonth() + 1)).slice(-2);
        this.timePeriod = this.fromdate + ' bis ' + this.todate;
      } else if (index == 3) {
        this.fromdate = '1000-01';
        this.todate = this.currentDate.getFullYear() + '-' + ('0' + (this.currentDate.getMonth() + 1)).slice(-2);
        this.timePeriod = 'Gesamtumsatz';
      }
      Revenue.getArticleTypesList(this.lfdnr, this.fromdate, this.todate).then(response => {
        this.articleTypesRevenue = response.data;
        this.loading = false;
      });
    },
    handleArticleTypesRow(value) {
      if (this.typeCategoryArticleTab == 0) {
        this.articleType = value.articleType;
        this.typeCategoryArticleTab = 1;
        this.articleTypesRevenue = [];
        this.loadArticleCategories(value.articleType);
      } else if (this.typeCategoryArticleTab == 1) {
        this.articleCategory = value.category;
        this.typeCategoryArticleTab = 2;
        this.loadArticles(value.category);
      }
    },
    loadArticleTypes() {
      this.articleType = -1;
      this.articleCategory = -1;
      Revenue.getArticleTypesList(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
        this.articleTypesRevenue = response.data;
      });
    },
    loadArticleCategories(type) {
      this.articleCategory = -1;
      Revenue.getCustomerArticleCategories(this.lfdnr, type, this.filterFromDate, this.filterToDate).then(response => {
        this.articleTypesRevenue = response.data;
      });
    },
    loadArticles(category) {
      Revenue.getCustomerArticles(this.lfdnr, category, this.filterFromDate, this.filterToDate).then(response => {
        this.articleTypesRevenue = response.data;
      });
    },
    formatEuro(value) {
      return currencyFormatService.formatEuro(value);
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    }),
    chartOptions: function () {
      var opts = this.chartOpts;
      opts.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          this.openLineChart(config.dataPointIndex);
          this.openRevenueDataTable(config.dataPointIndex);
        }
      };
      return opts;
    },
    computedLineChartOptions: function () {
      var opts = this.lineChartOptions;
      opts.chart.events = {
        click: (event, chartContext, config) => {
          if (this.selectedLineChartIndex != config.dataPointIndex) {
            this.chartArticleTable = this.lineChartData.at(config.dataPointIndex).invoices.slice();
            this.selectedLineChartIndex = config.dataPointIndex;
          } else {
            this.selectedLineChartIndex = -1;
            this.chartArticleTable.length = 0;
          }
        }
      };
      return opts;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    lfdnr() {
      this.init();
    },
    filterFromDate: function () {
      this.openedWithChart = false;
      this.loadArticleTypeStatistics();
      this.openLineChart(-1);
    },
    filterToDate: function () {
      this.openedWithChart = false;
      this.loadArticleTypeStatistics();
      this.openLineChart(-1);
    }
  }
};