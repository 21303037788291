import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "alignInformationTab"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Verband",
      "outlined": "",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.association,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "association", $$v);
      },
      expression: "address.healthInsuranceCompany.association"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Tarifgruppe",
      "outlined": "",
      "prepend-inner-icon": "mdi-account-cash",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.wageGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "wageGroupName", $$v);
      },
      expression: "address.healthInsuranceCompany.wageGroupName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Fibugruppe",
      "outlined": "",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.fibuGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "fibuGroupName", $$v);
      },
      expression: "address.healthInsuranceCompany.fibuGroupName"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kundennummer Warnwirtschaft",
      "append-icon": "mdi-numeric",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.kdNr,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "kdNr", $$v);
      },
      expression: "address.healthInsuranceCompany.kdNr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Vertragspartnernummer",
      "append-icon": "mdi-handshake"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "UID",
      "append-icon": "mdi-identifier",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.uid,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "uid", $$v);
      },
      expression: "address.healthInsuranceCompany.uid"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Abrechnung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Leistungszuständiger Versicherungsträger",
      "prepend-inner-icon": "mdi-account-tie",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.serviceInsuranceCarrierName,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "serviceInsuranceCarrierName", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.accounting\n              .serviceInsuranceCarrierName\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "xl": "2",
      "md": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Bundesland",
      "prepend-inner-icon": "mdi-map-legend",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.serviceState,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "serviceState", $$v);
      },
      expression: "address.healthInsuranceCompany.accounting.serviceState"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Datenzuständiger Versicherungsträger",
      "prepend-inner-icon": "mdi-account-tie",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.dataInsuranceCarrierName,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "dataInsuranceCarrierName", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.accounting.dataInsuranceCarrierName\n          "
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Abrechnungszuständiger Versicherungsträger",
      "prepend-inner-icon": "mdi-account-tie",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.accountingInsuranceCarrierName,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "accountingInsuranceCarrierName", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.accounting\n              .accountingInsuranceCarrierName\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "xl": "2",
      "md": "2"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Bundesland",
      "prepend-inner-icon": "mdi-map-legend",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.accountingState,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "accountingState", $$v);
      },
      expression: "address.healthInsuranceCompany.accounting.accountingState"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VCheckbox, {
    staticClass: "disabledCheckbox",
    attrs: {
      "label": "Fremdrechnungsnummer",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.externalInvoiceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "externalInvoiceNumber", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.accounting.externalInvoiceNumber\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Selbstbehalt-Rechnungstrennung",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.accounting.excessInvoiceSeparation,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.accounting, "excessInvoiceSeparation", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.accounting.excessInvoiceSeparation\n          "
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Dokumentsteuerung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "xl": "2",
      "md": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Sammelr. Int.",
      "suffix": "Tage",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.collectiveInvoiceInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "collectiveInvoiceInterval", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl\n              .collectiveInvoiceInterval\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "5",
      "xl": "5",
      "md": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Sammelrechnungstyp",
      "prepend-inner-icon": "mdi-receipt-text-outline",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.collectiveInvoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "collectiveInvoiceType", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl\n              .collectiveInvoiceType\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "5",
      "xl": "5",
      "md": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Sammelrechnungsdeckblatt",
      "prepend-inner-icon": "mdi-receipt-text",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.collectiveInvoiceCoverSheet,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "collectiveInvoiceCoverSheet", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl\n              .collectiveInvoiceCoverSheet\n          "
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Eigenanteilsgruppe",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.ownContributionGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "ownContributionGroup", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl\n              .ownContributionGroup\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Datenträger",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.dataCarrier,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "dataCarrier", $$v);
      },
      expression: "address.healthInsuranceCompany.documentControl.dataCarrier"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Bruttopreise",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.grossPrices,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "grossPrices", $$v);
      },
      expression: "address.healthInsuranceCompany.documentControl.grossPrices"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Gruppenrechnung",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.groupInvoice,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "groupInvoice", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl.groupInvoice\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Selbstbehalt",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.excess,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "excess", $$v);
      },
      expression: "address.healthInsuranceCompany.documentControl.excess"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Nulltarifautomatik",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.autoFreeAdmission,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany, "autoFreeAdmission", $$v);
      },
      expression: "address.healthInsuranceCompany.autoFreeAdmission"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Bundeslandcheck",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.checkState,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "checkState", $$v);
      },
      expression: "address.healthInsuranceCompany.documentControl.checkState"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "md": "8",
      "xl": "8"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Pauschale-Überschreitung Code",
      "disabled": ""
    },
    model: {
      value: _vm.address.healthInsuranceCompany.documentControl.lumSumExcessCode,
      callback: function ($$v) {
        _vm.$set(_vm.address.healthInsuranceCompany.documentControl, "lumSumExcessCode", $$v);
      },
      expression: "\n            address.healthInsuranceCompany.documentControl.lumSumExcessCode\n          "
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };