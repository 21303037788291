import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
import Revenue from '@/services/statistics/revenues.js';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
export default {
  components: {
    TimeFilter
  },
  data: () => ({
    revenueData: [],
    showLineChart: -1,
    headers: [{
      text: 'bis Anfangsdatum',
      value: 'revenueFromBeginning',
      align: 'end'
    }, {
      text: 'definierter Zeitraum',
      value: 'revenue',
      align: 'end'
    }, {
      text: 'seit Enddatum',
      value: 'revenueUntil Now',
      align: 'end'
    }, {
      text: 'Gesamtumsatz',
      value: 'total Sales',
      align: 'end'
    }],
    series: [{
      name: 'Euro (€)',
      data: []
    }],
    chartOpts: {
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top' // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: false,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000000']
        },
        formatter: value => currencyFormatService.formatEuro(value)
      },
      xaxis: {
        categories: [''],
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: value => currencyFormatService.formatEuro(value)
        }
      },
      tooltip: {
        shared: false,
        y: {
          formatter: value => {
            return currencyFormatService.formatEuro(value);
          }
        }
      },
      colors: ['#FF6633', '#ED0713', '#338B36'],
      set setXaxis(value) {
        this.xaxis = value;
      },
      set setTitle(value) {
        this.title = value;
      }
    },
    lineChartOptions: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      legend: {
        showForSingleSeries: true
      },
      dataLabels: {
        enabled: true,
        formatter: value => currencyFormatService.formatEuro(value)
      },
      markers: {
        size: 0
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        }
      },
      yaxis: {
        labels: {
          formatter: value => currencyFormatService.formatEuro(value)
        },
        title: {
          text: 'Umsatz'
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'dd.MM.yyyy',
            day: 'dd.MM.yyyy',
            hour: 'HH:mm'
          }
        }
      },
      tooltip: {
        shared: true,
        x: {
          format: 'dd.MM.yyyy'
        },
        y: {
          formatter: value => currencyFormatService.formatEuro(value)
        }
      }
    }
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      this.loadStatistics();
    },
    loadStatistics() {
      this.showLineChart = -1;
      var chartData = [];
      var chartRevenueData = [];
      var chartPersData = [];
      var chartKKData = [];
      Revenue.getKKRevenue(this.lfdnr, '1000-01', this.filterFromDate).then(response => {
        var obj = response.data[0];
        chartRevenueData.push(obj.revenue);
        chartPersData.push(obj.sbh);
        chartKKData.push(obj.kk_revenue);
        Revenue.getKKRevenue(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
          var obj = response.data[0];
          chartRevenueData.push(obj.revenue);
          chartPersData.push(obj.sbh);
          chartKKData.push(obj.kk_revenue);
          var heute = new Date();
          var monat = heute.getMonth() + 1;
          monat = monat.toString();
          if (monat.length < 2) {
            monat = '0' + monat;
          }
          Revenue.getKKRevenue(this.lfdnr, this.filterToDate, heute.getFullYear().toString() + '-' + monat.toString()).then(response => {
            var obj = response.data[0];
            chartRevenueData.push(obj.revenue);
            chartPersData.push(obj.sbh);
            chartKKData.push(obj.kk_revenue);
            Revenue.getKKRevenue(this.lfdnr, '1000-01', heute.getFullYear().toString() + '-' + monat.toString()).then(response => {
              var obj = response.data[0];
              chartRevenueData.push(obj.revenue);
              chartPersData.push(obj.sbh);
              chartKKData.push(obj.kk_revenue);
              chartData.push({
                name: 'Gesamtumsatz',
                data: chartRevenueData
              });
              chartData.push({
                name: 'Selbstbehalt',
                data: chartPersData
              });
              chartData.push({
                name: 'KK-Anteil',
                data: chartKKData
              });
              this.updateChart(chartData);
            });
          });
        });
      });
    },
    updateChart(chartData) {
      var chartHeaders = [];
      this.headers.forEach(item => {
        chartHeaders.push(item.text);
      });

      //this.chartOptions.categories = chartHeaders
      //this.lineChartOptions.title = this.address.name + ' - Gesamtstatistik'
      this.$refs.summaryChart.updateOptions({
        xaxis: {
          categories: chartHeaders
        }
      });
      this.$refs.summaryChart.updateSeries(chartData);
    },
    toggleLineChart(index) {
      if (index == this.showLineChart) {
        this.showLineChart = -1;
      } else {
        this.showLineChart = index;
        var startdate = '';
        var enddate = '';
        var heute = new Date();
        switch (index) {
          case 0:
            startdate = '1000-01';
            enddate = this.filterFromDate;
            break;
          case 1:
            startdate = this.filterFromDate;
            enddate = this.filterToDate;
            break;
          case 2:
            startdate = this.filterToDate;
            enddate = heute.getFullYear().toString() + '-' + ('0' + (heute.getMonth() + 1)).slice(-2);
            break;
          case 3:
            startdate = '1000-01';
            enddate = heute.getFullYear().toString() + '-' + ('0' + (heute.getMonth() + 1)).slice(-2);
            break;
        }
        Revenue.getKKRevenueMonthly(this.lfdnr, startdate, enddate).then(response => {
          this.lineChartData = response.data;
          var arr = response.data;
          this.revenueData.length = 0; //clear arr
          arr.forEach(item => {
            this.revenueData.push({
              x: new Date(item.year + '-' + item.month).getTime(),
              y: item.revenue
            });
          });
          this.$refs.lineChart.updateSeries([{
            name: 'KK-Anteil',
            data: this.revenueData
          }], false, true);
        });
      }
    },
    formatEuro(value) {
      return currencyFormatService.formatEuro(value);
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    }),
    chartOptions: function () {
      var opts = this.chartOpts;
      opts.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          this.toggleLineChart(config.dataPointIndex);
        }
      };
      return opts;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    lfdnr() {
      this.init();
    },
    filterFromDate: function () {
      this.loadStatistics();
    },
    filterToDate: function () {
      this.loadStatistics();
    }
  }
};