import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "informationTabAlign"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "HV-Nr.",
      "outlined": "",
      "append-icon": "mdi-numeric",
      "readonly": true
    },
    model: {
      value: _vm.address.doctor.hvnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.doctor, "hvnr", $$v);
      },
      expression: "address.doctor.hvnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Fachrichtung",
      "outlined": "",
      "prepend-inner-icon": "mdi-stethoscope",
      "readonly": true
    },
    model: {
      value: _vm.address.doctor.subjectName,
      callback: function ($$v) {
        _vm.$set(_vm.address.doctor, "subjectName", $$v);
      },
      expression: "address.doctor.subjectName"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };