import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', [_c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.address.name
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    staticClass: "rounded-pill",
    attrs: {
      "color": "primary",
      "to": {
        name: 'statisticsArticleSearchRevenue',
        params: {
          lfdnr: _vm.lfdnr
        },
        query: this.$route.query
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v(" mdi-magnify ")]), _vm._v(" Artikelsuche ")], 1)], 1), _c('TimeFilter')], 1), _c(VDivider), _c('div', {
    staticClass: "mt-4",
    attrs: {
      "id": "chart"
    }
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Verordnungen")])]), _c(VCard, [_c('apexchart', {
    ref: "summaryChart",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('br'), _c('h5', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLineChart != -1,
      expression: "showLineChart != -1"
    }],
    staticClass: "text-h5 text-center"
  }, [_vm._v(" Umsatzdiagramm ")])]), _c(VCard, [_c('apexchart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLineChart != -1,
      expression: "showLineChart != -1"
    }],
    ref: "lineChart",
    attrs: {
      "type": "area",
      "height": "350",
      "options": _vm.computedLineChartOptions,
      "series": _vm.lineChartSeries
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedLineChartIndex != -1,
      expression: "selectedLineChartIndex != -1"
    }],
    staticClass: "my-4"
  }, [_c('h5', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLineChart != -1,
      expression: "showLineChart != -1"
    }],
    staticClass: "text-h5 text-center"
  }, [_vm._v(" Artikelauflistung ")])]), _c(VCard, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedLineChartIndex != -1,
      expression: "selectedLineChartIndex != -1"
    }]
  }, _vm._l(_vm.chartArticleTable, function (invoice) {
    return _c('div', {
      key: invoice.invoice_nr
    }, [_c('div', {
      staticClass: "ma-4 pt-3"
    }, [_c('h5', [_vm._v("RechnungsNr: " + _vm._s(invoice.invoice_nr))]), _c('hr')]), _c(VDataTable, {
      attrs: {
        "headers": _vm.articleListingHeader,
        "items": invoice.articles
      },
      scopedSlots: _vm._u([{
        key: "item.revenue",
        fn: function ({
          item
        }) {
          return [_c('span', [_vm._v(_vm._s(_vm.formatEuro(item.revenue)))])];
        }
      }], null, true)
    })], 1);
  }), 0)], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('br')]), _vm.showLineChart != -1 ? _c('div', [_c(VCard, [_c('br'), _c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz pro Artikeltyp")]), _c('p', {
    staticClass: "caption text-center"
  }, [_vm._v(_vm._s(_vm.timePeriod))]), _c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.articleTypeHeader,
      "items": _vm.articleTypesRevenue,
      "search": _vm.search,
      "loading": _vm.loading,
      "item-class": _vm.itemRowBackground2
    },
    on: {
      "click:row": _vm.openArticleType
    },
    scopedSlots: _vm._u([{
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatEuro(item.revenue)))])];
      }
    }], null, false, 1590987195)
  })], 1), _c('br'), _vm.articleCategoryCard ? _c(VCard, [_c('br'), _c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz pro Artikelkategorie")]), _c('p', {
    staticClass: "caption text-center"
  }, [_vm._v(_vm._s(_vm.timePeriod))]), _c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search2,
      callback: function ($$v) {
        _vm.search2 = $$v;
      },
      expression: "search2"
    }
  })], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.articleCategoryHeader,
      "items": _vm.articleCategoriesTable,
      "search": _vm.search2,
      "loading": _vm.loading2,
      "item-class": _vm.itemRowBackground2
    },
    on: {
      "click:row": _vm.openArticleKategory
    },
    scopedSlots: _vm._u([{
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatEuro(item.revenue)))])];
      }
    }], null, false, 1590987195)
  })], 1) : _vm._e(), _c('br'), _vm.articleArticleCard ? _c(VCard, [_c('br'), _c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz pro Artikel")]), _c('p', {
    staticClass: "caption text-center"
  }, [_vm._v(_vm._s(_vm.timePeriod))]), _c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search3,
      callback: function ($$v) {
        _vm.search3 = $$v;
      },
      expression: "search3"
    }
  })], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.articleArticleHeader,
      "items": _vm.articleArticlesTable,
      "search": _vm.search3,
      "loading": _vm.loading3,
      "item-class": _vm.itemRowBackground2
    },
    on: {
      "click:row": _vm.openArticleArticle
    },
    scopedSlots: _vm._u([{
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatEuro(item.revenue)))])];
      }
    }], null, false, 1590987195)
  })], 1) : _vm._e(), _c('br'), _c('RevenuePerArticleProperty', {
    attrs: {
      "visible": _vm.articleCharacteristicsCard,
      "time-period": _vm.timePeriod,
      "article-characteristics-table": _vm.articleCharacteristicsTable,
      "loading": _vm.loading4
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };