import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', [_c('TimeFilter')], 1), _c(VDivider), _c('div', {
    staticClass: "mt-4",
    attrs: {
      "id": "chart"
    }
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz")])]), _c(VCard, [_c('apexchart', {
    ref: "summaryChart",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('br'), _c('h5', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLineChart != -1,
      expression: "showLineChart != -1"
    }],
    staticClass: "text-h5 text-center"
  }, [_vm._v(" Umsatzdiagramm ")])]), _c(VCard, [_c('apexchart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLineChart != -1,
      expression: "showLineChart != -1"
    }],
    ref: "lineChart",
    attrs: {
      "type": "area",
      "height": "350",
      "options": _vm.lineChartOptions,
      "series": _vm.lineChartSeries
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };