import axios from 'axios'

export default {
  //https://nominatim.org/release-docs/develop/api/Search/
  async getGeocodeByAddress(address) {
    return axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        //street=Sonnensiedlung 33&city=Ardagger Markt&postalcode=3321&format=jsonv2&limit=1
        street: address.street,
        city: address.city,
        postalcode: address.zip,
        format: 'jsonv2',
        limit: 1
      }
    })
  }
}
