import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c(VCard, [_c('br'), _c('h5', {
    staticClass: "text-h5 text-center mt-3"
  }, [_vm._v("Umsatz pro Artikelmerkmal")]), _c('p', {
    staticClass: "caption text-center"
  }, [_vm._v(_vm._s(_vm.timePeriod))]), _c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.articleArticleCharacteristicsHeader,
      "items": _vm.articleCharacteristicsTable,
      "search": _vm.search,
      "loading": _vm.loading,
      "item-class": _vm.itemRowBackground,
      "show-expand": ""
    },
    scopedSlots: _vm._u([{
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatEuro(item.revenue)))])];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        item,
        headers
      }) {
        return [_c('td', {
          staticClass: "py-3",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_vm._v("Allgemeine Eigenschaften:")]), _c('hr'), _c(VSimpleTable, {
          staticClass: "mt-2"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Eigenschaft")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Wert")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Artikelnummer")]), _c('td', [_vm._v(_vm._s(item.articleNumber))])]), _c('tr', [_c('td', [_vm._v("Artikelsachmerkmal EAN")]), _c('td', [_vm._v(_vm._s(item.articlePropertyEAN))])]), _c('tr', [_c('td', [_vm._v("Artikelsachmerkmal Lfdnr.")]), _c('td', [_vm._v(_vm._s(item.articlePropertyNumber))])])])]), _c('h5', {
          staticClass: "mt-7"
        }, [_vm._v("Sachmerkmal-Eigenschaften:")]), _c('hr'), _c(VSimpleTable, {
          staticClass: "mt-2"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Nummer")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Bezeichnung")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Wert")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v(_vm._s(item.articleProperty_1_description))]), _c('td', [_vm._v(" " + _vm._s(item.articleProperty_1_value == null ? '-' : item.articleProperty_1_value) + " ")])]), _c('tr', [_c('td', [_vm._v("2")]), _c('td', [_vm._v(_vm._s(item.articleProperty_2_description))]), _c('td', [_vm._v(" " + _vm._s(item.articleProperty_2_value == null ? '-' : item.articleProperty_2_value) + " ")])]), _c('tr', [_c('td', [_vm._v("3")]), _c('td', [_vm._v(_vm._s(item.articleProperty_3_description))]), _c('td', [_vm._v(" " + _vm._s(item.articleProperty_3_value == null ? '-' : item.articleProperty_3_value) + " ")])])])])], 1)];
      }
    }], null, false, 60686335)
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };