export default {
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    }
  }
};